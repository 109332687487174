import '../-components/display/generator-skip-link.js'
import InitMatgenCore from '@m4c/matgen/init-matgen.js'
import { a11yClick } from '../-components/display/a11y-click.js'
import {
  authCallback,
  buildQueryString,
  configure,
  emit,
  getQueryParam,
} from './data/index.js'
import {
  authUI,
  mainContentLoader,
  mainContentLoaderStop,
  showModal,
} from './display.js'
import initEvents from './events.js'

// import posthog from 'posthog-js';

export const initAuth = async () => {
  if (MatgenGlobal.AuthInitStarted) {
    return
  }
  MatgenGlobal.AuthInitStarted = true
  MatgenGlobal.AuthCallback = authCallback
  let user
  if (!MatgenGlobal.AuthUser) {
    MatgenGlobal.AuthUser = new MatgenGlobal.AuthUserModule()
  }
  if (!MatgenGlobal.AuthUser.user) {
    if (navigator.onLine) {
      user = MatgenGlobal.AuthUser
        ? await MatgenGlobal.AuthUser.getUser()
        : false
      if (!user) {
        MatgenGlobal.AuthUser = new MatgenGlobal.AuthUserModule()
        await MatgenGlobal.AuthUser.getUser()
      }
    }
  }

  authUI()
}

export const init = async () => {
  initEvents()
  InitMatgenCore()

  mainContentLoader({ showMessage: true })
  $('#loader-message').text('Checking authentication...')

  MatgenGlobal.tenant_id = '3ef2b6ec-f02a-4b3c-9864-9d0d02f124fd'
  await configure()

  let maintenance = await MatgenGlobal.Amplify.API.get(
    'public',
    `/maintenance?cache_buster=${new Date().getTime()}`
  )

  if (maintenance && Array.isArray(maintenance) && maintenance.length > 0) {
    maintenance = true
  } else {
    maintenance = false
  }

  if (maintenance && !window.location.href.includes('maintenance.html')) {
    window.location.href = '/maintenance.html'
  }

  MatgenGlobal.MainContentWrapper = '#main-content'
  MatgenGlobal.MainContentContainer = '#main-whole'

  MatgenGlobal.tenant = 'nia'
  MatgenGlobal.suppressMenus = true

  MatgenGlobal.a11yClick = a11yClick
  MatgenGlobal.M4CModal.show = showModal
  MatgenGlobal.getQueryParam = getQueryParam
  MatgenGlobal.buildQueryString = buildQueryString

  const flag = MatgenGlobal.getQueryParam('flag')
  if (flag && flag === 'signup_confirm') {
    MatgenGlobal.suppressMainLoaderStop = true
    dataLayer.push({
      event: 'create-account',
      eventCallback: () => {
        window.location.href = '/'
        mainContentLoaderStop()
        delete MatgenGlobal.suppressMainLoaderStop
      },
    })
  }

  const reviewFlag = MatgenGlobal.getQueryParam('review')
  if (reviewFlag) {
    sessionStorage.setItem('matgen-review', true)
  }

  MatgenGlobal.BaseInit()
  if (!MatgenGlobal.globalsInitialized) {
    await initAuth()
    MatgenGlobal.globalsInitialized = true
    MatgenGlobal.emit({ event: 'matgen-event-globals-initialized' })
  } else {
    MatgenGlobal.globalsInitialized = true
    MatgenGlobal.emit({ event: 'matgen-event-globals-initialized' })
  }

  if (!MatgenGlobal.MatgenBootInitComplete) {
    emit({
      event: 'matgen-boot-init-complete',
    })
    MatgenGlobal.MatgenBootInitComplete = true
  }
}

export default init
