import { Workbox } from 'workbox-window'
import { init as globalInit } from './index.js'

window.SW_DEBUG = SW_DEBUG ?? false

const queryString = window.location.search
const urlParams = new URLSearchParams(queryString)
const swClear = urlParams.get('swClear')

const initSignal = () => {
  if (!window.initSignalFired) {
    window.dispatchEvent(
      new CustomEvent('matgen-service-worker-init', {
        wb: Workbox,
      })
    )
    window.initSignalFired = true
  }
}

const authInit = () => {
  if (typeof MatgenGlobal === 'undefined') {
    window.MatgenGlobal = {}
  }
  if (typeof M4CGlobal === 'undefined') {
    window.M4CGlobal = {}
  }

  if (!MatgenGlobal.globalsInitialized) {
    MatgenGlobal.debug = true
    globalInit()
  }
}

const wipe = () => {
  navigator.serviceWorker.getRegistrations().then(registrations => {
    const unregisterPromises = []
    const cacheDeletionPromises = []
    for (const registration of registrations) {
      unregisterPromises.push(registration.unregister())
    }
    caches.keys().then(allCaches => {
      allCaches.forEach(cache => {
        cacheDeletionPromises.push(caches.delete(cache))
      })
      Promise.all(cacheDeletionPromises.concat(unregisterPromises)).then(() => {
        window.location.href = '/'
      })
    })
  })
}

let refreshing
navigator.serviceWorker.addEventListener('controllerchange', function () {
  if (refreshing) {
    return
  }
  refreshing = true
  window.location.reload()
})

const swInit = () => {
  if ('serviceWorker' in navigator) {
    const wb = new Workbox('/sw.js', { type: 'module' })

    wb.addEventListener('waiting', () => {
      wb.addEventListener('controlling', () => {
        if (refreshing) {
          return
        }
        refreshing = true
        window.location.reload()
      })
      wb.messageSkipWaiting()
    })

    if (swClear === 'clear') {
      wipe()
    } else {
      wb.register()
        .then(function () {
          authInit()
        })
        .catch(function (error) {
          console.error('Service worker registration failed:', error)
        })
    }
  } else {
    console.error('Service workers are not supported.')
  }
}

window.initSignalFired = false
window.addEventListener('load', () => {
  initSignal()
})

const handleUnhandledJSError = () => {
  $.get('/js-error.html', function (data) {
    document.open()
    document.write(data)
    document.close()
  }).fail(function () {
    window.location.href = '/js-error.html'
  })
}

window.onunhandledrejection = event => {
  // console.warn(`UNHANDLED PROMISE REJECTION: ${event.reason}`)
  handleUnhandledJSError()
}

window.onerror = function (message, source, lineNumber, colno, error) {
  // console.warn(`UNHANDLED ERROR: ${error.stack}`)
  handleUnhandledJSError()
}

window.addEventListener('matgen-service-worker-init', e => {
  swInit()
})
